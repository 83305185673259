import { PROPERTY_TYPE_DEFAULT } from '@/config/property-types';

/*
 * @TODO: URL generation for this links isn't taking into account URL translation for other languages.
 * To fix this we might need to have all details translated or it is the responsibility of the destination page, to
 * translated to the correct url as it does at the moment, but it's one more redirect
 * Also, prices are always in Euros, for every language/currency
 */

export function popularCities() {
  const { useUrl } = useUrls();
  const { t } = useI18n();

  const actualPropertyTypeObj = PROPERTY_TYPE_DEFAULT;

  return [
    [
      {
        name: 'London',
        cityTranscode: 't_LONDONHP',
        instagramUser: 'giannicersosimo',
        priceFrom: '€10',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/london.jpg' },
        links: {
          url: useUrl.getCityPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'europe',
            urlFriendlyCountry: 'england',
            urlFriendlyCity: 'london',
          }),
        },
      },
      {
        name: 'Berlin',
        cityTranscode: 't_BERLINHP',
        instagramUser: 'camiladasilvala',
        priceFrom: '€10',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/berlin.jpg' },
        links: {
          url: useUrl.getCityPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'europe',
            urlFriendlyCountry: 'germany',
            urlFriendlyCity: 'berlin',
          }),
        },
      },
    ],
    [
      {
        name: 'Amsterdam',
        cityTranscode: 't_AMSTERDAMHP',
        instagramUser: '_federicalillo',
        priceFrom: '€22',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/amsterdam.jpg' },
        links: {
          url: useUrl.getCityPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'europe',
            urlFriendlyCountry: 'netherlands',
            urlFriendlyCity: 'amsterdam',
          }),
        },
      },
      {
        name: 'Rome',
        cityTranscode: 't_ROMEHP',
        instagramUser: 'theyellowrome',
        priceFrom: '€10',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/rome.jpg' },
        links: {
          url: useUrl.getCityPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'europe',
            urlFriendlyCountry: 'italy',
            urlFriendlyCity: 'rome',
          }),
        },
      },
    ],
    [
      {
        name: 'Barcelona',
        cityTranscode: 't_BARCELONAHP',
        instagramUser: 'noe.gf',
        priceFrom: '€11',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/barcelona.jpg' },
        links: {
          url: useUrl.getCityPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'europe',
            urlFriendlyCountry: 'spain',
            urlFriendlyCity: 'barcelona',
          }),
        },
      },
      {
        name: 'New Zealand',
        cityTranscode: 't_NEWZEALANDHP',
        instagramUser: 'imlucyirving',
        priceFrom: '€18',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/new_zealand.jpg' },
        links: {
          url: useUrl.getCountryPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'oceania',
            urlFriendlyCountry: 'new-zealand',
          }),
        },
      },
    ],
    [
      {
        name: 'Paris',
        cityTranscode: 't_PARISHP',
        instagramUser: 'asyaballery',
        priceFrom: '€21',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/paris.jpg' },
        links: {
          url: useUrl.getCityPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'europe',
            urlFriendlyCountry: 'france',
            urlFriendlyCity: 'paris',
          }),
        },
      },
      {
        name: 'Dublin',
        cityTranscode: 't_DUBLINHP',
        instagramUser: '4on5away',
        priceFrom: '€18',
        image: { src: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_220/pwa/dublin.jpg' },
        links: {
          url: useUrl.getCityPageUrl({
            propertyTypeSlug: t(actualPropertyTypeObj?.slug),
            urlFriendlyContinent: 'europe',
            urlFriendlyCountry: 'ireland',
            urlFriendlyCity: 'dublin',
          }),
        },
      },
    ],
  ];
}
