<script setup>
import { WdsIcon } from '@wds/components';
import { popularCities } from './constants.js';

const { $tracking } = useNuxtApp();

function onCityClicked(city) {
  const trackingInfo = {
    segment: {
      event_name: 'Homepage Event',
      action: 'City clicked',
      article_clicked: city,
    },
    gtm: { parameter1: city },
  };
  $tracking.onUnmissableCitiesInteraction(trackingInfo.gtm, trackingInfo.segment);
}
</script>

<template>
  <div class="popular-places-container">
    <h2 class="title title-3-bld">
      {{ $t('t_GETINSPIRED') }}
    </h2>

    <h3 class="sub-title body-1-reg">
      {{ $t('t_DISCOVERPOPUL') }}
    </h3>

    <div class="mosaic">
      <div
        v-for="(cityRow, index) in popularCities()"
        :key="index"
        class="mosaic-inner"
      >
        <a
          v-for="city in cityRow"
          :key="city.name"
          :href="city.links.url"
          class="card-wrapper"
          @click="onCityClicked(city.name)"
        >
          <div class="city-card">
            <div class="city-card__info">
              <img
                :src="city.image.src"
                :alt="$t(city.cityTranscode)"
                loading="lazy"
                :title="$t('t_BOOKHOSTELS', { CITY: $t(city.cityTranscode) })"
                class="city-card__info-img"
              />
              <span
                class="city-card__info-overlay"
                :title="$t('t_BOOKHOSTELS', { CITY: $t(city.cityTranscode) })"
              >
                <span class="city-card__info-city title-5-bld"> {{ $t(city.cityTranscode) }} </span>
              </span>
            </div>
            <div
              class="city-card__social-handle"
              :title="$t('t_BOOKHOSTELS', { CITY: $t(city.cityTranscode) })"
            >
              <WdsIcon
                asset="sn-instagram"
                class="wds-icon"
                loading="lazy"
              />
              <span>{{ city.instagramUser }}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popular-places-container {
  max-width: wds-rem(1600px);
  margin: 0 auto;
  padding: $wds-spacing-l 0 wds-rem(40px) $wds-spacing-m;

  .title {
    color: $wds-color-ink-darker;
  }

  .sub-title {
    color: $wds-color-ink-darker;
  }

  .mosaic {
    display: flex;
    margin-top: wds-rem(30px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    a:hover {
      text-decoration: none;
    }

    &-inner {
      display: flex;
      margin-right: $wds-spacing-l;
      flex: 0 0 auto;

      .card-wrapper {
        width: wds-rem(220px);
        text-decoration: none;
      }
    }

    .card-wrapper:first-child {
      margin-right: $wds-spacing-l;
    }

    .city-card {
      &__info {
        position: relative;

        &-img {
          border-radius: $wds-spacing-l;
          display: block;
          width: wds-rem(220px);
          height: wds-rem(296px);
        }

        &-overlay {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        &-city {
          color: $wds-color-white;
          background-color: $wds-color-ink-darker;
          padding: $wds-spacing-xs $wds-spacing-s;
          margin-top: $wds-spacing-l;
        }
      }

      &__social-handle {
        margin-top: $wds-spacing-s;
        display: flex;
        justify-content: flex-end;
        color: $wds-color-ink;
        width: wds-rem(220px);
        align-items: center;
        text-decoration: none;

        .wds-icon {
          color: $wds-color-ink;
          fill: $wds-color-ink;
        }

        span {
          margin-left: 0.2rem;
        }
      }
    }
  }

  @include tablet {
    padding-left: $wds-spacing-l;

    .mosaic {
      margin-top: wds-rem(54px);

      &-inner {
        margin-right: $wds-spacing-l;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  @include desktop {
    padding-right: $wds-spacing-l;

    .mosaic {
      overflow-y: hidden;

      .card-wrapper:first-child {
        margin-right: 0;
        margin-bottom: $wds-spacing-xl;
      }

      &-inner {
        flex: auto;
        flex-direction: column;
        margin-right: $wds-spacing-xxl;

        &:nth-child(even) {
          margin-top: wds-rem(89px);
        }
      }
    }
  }

  @include desktop-large {
    padding-left: wds-rem(40px);
    padding-right: wds-rem(40px);

    .mosaic {
      .card-wrapper:first-child {
        margin-bottom: wds-rem(50px);
      }

      &-inner {
        margin-right: $wds-spacing-xl;
      }
    }
  }
}
</style>
